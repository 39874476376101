import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import jwtDecode from "jwt-decode";
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import NavConfig from './NavConfig';
import NavConfigAdmin from './NavConfigAdmin';
import NavConfigManufacturer from './NavConfigManufacturer';
import NavConfigServicePartner from './NavConfigServicePartner';
// import NavbarDocs from './NavbarDocs';
// import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import LogoM from "../../../components/LogoM";
import useAuth from "../../../hooks/useAuth";
import NavConfigSuperuser from "./NavConfigSuperuser";
import {useEnv} from "../../../contexts/EnvContext";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.shorter,
        }),
    },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
    const navConfig = NavConfig();
    const navConfigAdmin = NavConfigAdmin();
    const navConfigSuperuser = NavConfigSuperuser();
    const navConfigManufacturer = NavConfigManufacturer();
    const navConfigServicePartner = NavConfigServicePartner();

    const theme = useTheme();

    const { pathname } = useLocation();

    const isDesktop = useResponsive('up', 'lg');

    const [navigationConfig, setNavigationConfig] = useState(navConfig);

    const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
        useCollapseDrawer();

    const authContext = useAuth()
    const { getTenant } = useEnv()

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        async function retrieveClaims() {
            const token = await authContext.getToken();
            const tenant = getTenant()
            const jwtDecoded = jwtDecode(token);
            const {permissions} = jwtDecoded;
            if (permissions.includes(`role:superuser`)) {
                setNavigationConfig(navConfigSuperuser);
            } else if (permissions.includes(`role:${tenant}/admin`)) {
                setNavigationConfig(navConfigAdmin);
            } else if (permissions.includes(`role:${tenant}/manufacturer`)) {
                setNavigationConfig(navConfigManufacturer);
            } else if (permissions.includes(`role:${tenant}/servicepartner`)) {
                setNavigationConfig(navConfigServicePartner);
            } else {
                setNavigationConfig(navConfig);
            }
        }
        retrieveClaims();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                    ...(isCollapse && { alignItems: 'center' }),
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {isDesktop && !isCollapse && (
                        <LogoM />
                    )}
                    {isDesktop && isCollapse && (
                        <Logo />
                    )}

                    {isDesktop && !isCollapse && (
                        <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
                    )}
                </Stack>

            </Stack>
                <NavSectionVertical navConfig={navigationConfig} isCollapse={isCollapse} />
            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <RootStyle
            sx={{
                width: {
                    lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
                },
                ...(collapseClick && {
                    position: 'absolute',
                }),
            }}
        >
            {!isDesktop && (
                <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: NAVBAR.DASHBOARD_WIDTH,
                            borderRightStyle: 'dashed',
                            bgcolor: 'background.default',
                            transition: (theme) =>
                                theme.transitions.create('width', {
                                    duration: theme.transitions.duration.standard,
                                }),
                            ...(isCollapse && {
                                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                            }),
                            ...(collapseHover && {
                                ...cssStyles(theme).bgBlur(),
                                boxShadow: (theme) => theme.customShadows.z24,
                            }),
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}
