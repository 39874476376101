// routes
import {PATH_PAGE} from '../../../routes/paths';
import Iconify from '../../../components/Iconify';
import useLocales from "../../../hooks/useLocales";

// ----------------------------------------------------------------------
const SIZE = {
  width: 24,
  height: 24,
};
const getIcon = (name) => <Iconify icon={`${name}`} sx={{ ...SIZE, color: '#666' }} />;

const ICONS = {
  customer: getIcon('ic:baseline-adjust'),
  equipment: getIcon('ic:outline-folder'),
  product: getIcon('ic:sharp-list-alt'),
  order: getIcon('ic:outline-request-quote'),
  settings: getIcon('ic:outline-settings'),
  admin: getIcon('ic:outline-admin-panel-settings'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic:baseline-person-outline'),
  integration: getIcon('ic:round-code'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic:outline-space-dashboard'),
  booking: getIcon('ic_booking'),
  allocate: getIcon('ic:outline-ballot'),
};

const NavConfig  = () => {

  const {translate} = useLocales();

  return [
    // MAINTANIO
    // ----------------------------------------------------------------------
    {
      subheader: translate('nav.main.headline'),
      items: [
        // {title: translate('nav.main.dashboard'), path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard},
        {title: translate('nav.main.customer'), path: PATH_PAGE.customer.list, icon: ICONS.customer},
        // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
        {title: translate('nav.main.equipment'), path: PATH_PAGE.equipment.list, icon: ICONS.equipment},
        {title: translate('nav.main.product'), path: PATH_PAGE.product.list, icon: ICONS.product},
        {title: translate('nav.main.case'), path: PATH_PAGE.case.list, icon: ICONS.order},
        // {title: 'sandbox', path: '/sandbox', icon: ICONS.order},
      ],
    },
    // Admin
    // ----------------------------------------------------------------------
    /* {
      subheader: translate('nav.admin.headline'),
      items: [
        {title: translate('nav.admin.assignment'), path: PATH_DASHBOARD.general.app, icon: ICONS.allocate},
        // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
        // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
        // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
        // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      ],
    } */
//
// MANAGEMENT
// ----------------------------------------------------------------------
    {
    subheader: translate('nav.management.headline'),
    items: [
      // MANAGEMENT : USER
      { title: translate('nav.management.users'), path: PATH_PAGE.user.list, icon: ICONS.user },
      // { title: translate('nav.management.integration'), path: PATH_PAGE.integration.info, icon: ICONS.integration },
    ],
    },
  ];
}
export default NavConfig;
