import { Outlet } from 'react-router-dom';
// @mui


export default function SimpleLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}
