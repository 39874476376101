import {useEffect, useState} from "react";

// routes
import {ConfirmProvider} from "material-ui-confirm";

import useAuth from "./hooks/useAuth";


import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { DataServiceProvider } from "./contexts/DataServiceContext";
import {AuthProvider} from "./contexts/Auth0ContextProvider";
// ----------------------------------------------------------------------

export default function App() {
    const { getUser } = useAuth()
    const [supportButtonInstalled, setSupportButtonInstalled] = useState(false)

    useEffect(() => {
        (async () => {
            const user = getUser()
            if (user) {
                if (!supportButtonInstalled) {
                    // See https://developers.livechat.com/docs/extending-chat-widget/javascript-api
                    window.LiveChatWidget.init()
                    window.LiveChatWidget.call("set_customer_name", user.name);
                    window.LiveChatWidget.call("set_customer_email", user.email);
                    setSupportButtonInstalled(true)
                    console.debug("Support Button installed.")
                } else {
                    console.debug("Support Button already installed.")
                }
            }
        })()
    })

    return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ConfirmProvider>
                  <DataServiceProvider
                      defaultOptions={{
                        confirmationButtonProps: { autoFocus: true },
                      }}
                  >
                    <AuthProvider>
                        <ProgressBarStyle />
                        <ChartStyle />
                        <ScrollToTop />
                        <Router />
                    </AuthProvider>
                  </DataServiceProvider>
                </ConfirmProvider>
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
