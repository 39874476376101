import React, {useEffect, useRef} from 'react';
import LoadingBar from "react-top-loading-bar";

// @mui
import {Button, Container, Typography,} from '@mui/material';
// routes
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
// components
import Page from '../../components/Page';
// sections
import useLocales from "../../hooks/useLocales";
import useDataService from "../../hooks/useDataService";

// ----------------------------------------------------------------------

export default function Sandbox() {

    const { translate } = useLocales();
    const { themeStretch } = useSettings();

    const dataService = useDataService()
    const ref = useRef(null);

    useEffect(() => {
        ref.current.continuousStart()
        const timeout = setTimeout(() => {ref.current.complete();}, 5000)
        return () => {clearTimeout(timeout)}
    })


    // ============================================
    return (
        <Page title={translate("customerList.headline")}>
            <LoadingBar color='#f11946' ref={ref} />
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <Typography variant="h2"> Hello {dataService.refreshCounter} </Typography>

                <Button variant="outlined" onClick={() => {dataService.refresh()}}>Klick</Button>
            </Container>
        </Page>
    );
}
