import {useEffect, useState} from 'react';

// @mui
import { alpha } from '@mui/material/styles';
import {Box, Divider, Typography, MenuItem, Stack} from '@mui/material';
// routes

import {useConfirm} from "material-ui-confirm";

// hooks
import useAuth from '../../../hooks/useAuth';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useLocales from "../../../hooks/useLocales";
import useDataService from "../../../hooks/useDataService";

// ----------------------------------------------------------------------

/*
const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
];
*/

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { user } = useAuth();
  const authContext = useAuth()

  const confirm = useConfirm();

  const { translate } = useLocales();

  const { getToken } = useAuth();

  const dataService = useDataService("/users");
  const dataServiceEndpoints = dataService.endpoints;

  const [open, setOpen] = useState(null);
  const [displayName, setDisplayName] = useState(null);

    useEffect(() => {
        async function retrieveUser() {
            const sessionDisplayName = sessionStorage.getItem("displayName");
            if (sessionDisplayName == null && displayName == null) {
                const token = await getToken()

                if (user) {
                    const {email} = user

                    if (email) {
                        dataServiceEndpoints.get(email, null, token).then((response) => {
                            const user = response.data;
                            sessionStorage.setItem("displayName", `${user.firstname} ${user.lastname}`);
                            setDisplayName(`${user.firstname} ${user.lastname}`)
                        }).catch((e) => {
                            console.log(e);
                        });
                    }
                }
            } else {
                setDisplayName(sessionDisplayName)
            }
        }
        retrieveUser();
        // eslint-disable-next-line
    }, [user]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

    const handleLogout = () => {
        authContext.logout()
    };

    const sendPasswordChangeEmail = (id) => {
        confirm({title: translate("userCreate.form.confirm.title"), description: translate("userCreate.form.confirm.description"), confirmationText: translate("userCreate.form.confirm.button.ok"), cancellationText: translate("userCreate.form.confirm.button.cancel")}).then(async () => {
            if (id) {
                const baseUrl = `/users/${id}/sendPasswordChangeMail`;
                const userService = dataService.adhocEndpoints(baseUrl);
                const token = await getToken();
                userService.create(null, null, token).then((response) => {
                    console.log("sendPasswordChangeEmail sent", response);
                    setOpen(null);
                })
            }
        }).catch(() => { setOpen(null); });
    };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
            <MenuItem onClick={() => sendPasswordChangeEmail(user.email)}>
              Change Password
            </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
