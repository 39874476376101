import {createContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import {Auth0Client} from '@auth0/auth0-spa-js';
//
import jwtDecode from "jwt-decode";
import {useAuth0} from "@auth0/auth0-react";
import {PATH_AFTER_LOGIN} from '../config';
import {useEnv} from "./EnvContext";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

function reducer(state, action) {
  console.debug(`${action.type} action called`)
  switch (action.type) {
    case "INITIALIZE" : {
      const {isAuthenticated, user} = action.payload;
      return {...state, isAuthenticated, isInitialized: true, user}
    }
    case "LOGIN" : {
      const {user} = action.payload;
      return {...state, isAuthenticated: true, user}
    }
    case "LOGOUT" :
      return {...state, isAuthenticated: false, user: null}
    default :
      return state
  }
}

const AuthContext = createContext({
  ...initialState,
  method: 'auth0',
  getUser: () => Promise.resolve(),
  getToken: () => Promise.resolve(),
  getRole: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();
  const { getTenant, domain, clientId, audience } = useEnv();

  const auth0Client = new Auth0Client({
    clientId: clientId || '',
    domain: domain || '',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience,
    },
  });

  useEffect(() => {
    (async () => {
      try {
        await auth0Client.checkSession()
        if (isAuthenticated) {
          const user = await buildAndGetUser();
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated, user },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated, user: null },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: { isAuthenticated: false, user: null },
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  async function buildAndGetUser() {
    const token = await getToken();
    const tenant = getTenant();
    const jwtDecoded = jwtDecode(token);
    const {permissions} = jwtDecoded;
    let role = "none"
    if (permissions.includes(`role:${tenant}/servicepartner`)) {
      role = 'servicepartner'
    } else if (permissions.includes(`role:${tenant}/manufacturer`)) {
      role = 'manufacturer'
    } else if (permissions.includes(`role:${tenant}/user`)) {
      role = 'user'
    } else if (permissions.includes(`role:${tenant}/admin`)) {
      role = 'admin'
    }
    return {
      ...user,
      id: user.sub,
      photoURL: user.picture,
      displayName: user.nickname,
      role,
    };
  }

  async function login(returnTo) {
    await loginWithRedirect({
      appState: {
        returnTo: returnTo || PATH_AFTER_LOGIN,
      },
    })
    const user = await buildAndGetUser();
    dispatch({type: 'LOGIN', payload: {user}});
  }

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
    const returnTo = `${window.location.protocol}//${window.location.host}`;
    console.log("LOGOUT ReturnTo", returnTo)
    auth0Client?.logout({
      logoutParams: {
        returnTo
      }
    });
  };

  async function getToken() {
    let token = ''
    try {
      token = await getAccessTokenSilently();
      console.debug("Got token silently:", `${token.substr(0, 50)}...`)
      // eslint-disable-next-line no-empty
    } catch (ignored) {}
    return token;
  }

  function getUser() {
    return state.user
  }

  function getRole() {
    return state.user?.role
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'auth0',
        getUser,
        getRole,
        getToken,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
