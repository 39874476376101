// @mui
import {styled} from '@mui/material/styles';
import {Card, CardActions, CardContent, Container, Typography} from '@mui/material';
// components
import PropTypes from "prop-types";
import Page from '../../components/Page';
import Image from '../../components/Image';
// sections
import {LoginForm} from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
Login.propTypes = {
  returnTo: PropTypes.string,
};

export default function Login({ returnTo }) {
  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Card sx={{ minWidth: 100, width: 400, minHeight: 540, boxShadow: 15, borderRadius: 0.5, left: 40 }}>
              <CardContent sx={{padding: 5}}>
                <Image
                    alt="login"
                    src="/logo/maintanio.png"
                    sx={{width: 62.3167, margin: 'auto', marginTop: 2.2}}
                />
                <Typography align="center" sx={{ fontSize: 24, margin: 'auto', marginTop: 3.1, fontFamily: "ulp-font,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,sans-serif;" }}>
                  Herzlich Willkommen
                </Typography>
                <Typography align="center" sx={{ fontSize: 14, margin: 'auto', marginTop: 1.9, fontFamily: "ulp-font,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,sans-serif;" }}>
                  Loggen Sie sich ein, um die Maintanio Plattform nutzen zu können.
                </Typography>
                <Typography align="center" sx={{ fontSize: 14, margin: 'auto', marginTop: 1.9, height: 140, fontFamily: "ulp-font,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,sans-serif;" }}>
                  Sollten Sie noch kein eigenen Zugang haben, melden sie sich bitte bei ihrem Ansprechpartner oder senden uns eine Nachricht an <a href="mailto:info@maintanio.de">info@maintanio.de</a>.
                </Typography>
              </CardContent>
              <CardActions sx={{display: 'flex', flexDirection: "column", justifyContent: "space-between"}}>
                <LoginForm returnTo={returnTo} />
              </CardActions>
            </Card>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
