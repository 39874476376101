import { useContext } from "react";
import { DataServiceContext } from '../contexts/DataServiceContext';


function useDataService(baseUrl) {
    const context = useContext(DataServiceContext)
    if (context === undefined) {
        throw new Error('useDataService must be used within a DataServiceProvider')
    }

    function buildEnpoints(baseUrl) {
        function getAll(params, token) {
            console.debug(`DataService: ${baseUrl} - getAll(...)`, params)
            // return context.apiClientBuilder(token).get(`${baseUrl}`, {params})
            async function returnPromise() {
                const result = await context.apiClientBuilder(token).get(`${baseUrl}`, {params})
                console.debug(`DataService: ${baseUrl} - getAll(...) -> `, result.data)
                return result
            }
            return returnPromise()
        }

        function get(id, config, token) {
            console.debug(`DataService: ${baseUrl} - get(...)`, id, config)
            async function returnPromise() {
                const result = await context.apiClientBuilder(token).get(`${baseUrl}/${id}`, {config});
                console.debug(`DataService: ${baseUrl} - get(...) -> `, result.data)
                return result
            }
            return returnPromise()
        }

        function create(data, config, token) {
            console.debug(`DataService: ${baseUrl} - create(...)`, data, config)
            async function returnPromise() {
                const result = await context.apiClientBuilder(token).post(`${baseUrl}`, data, config);
                console.debug(`DataService: ${baseUrl} - create(...) -> `, result.data)
                return result
            }
            return returnPromise()
        }

        function update(data, config, token) {
            console.debug(`DataService: ${baseUrl} - update(...)`, data, config)
            async function returnPromise() {
                const result = await context.apiClientBuilder(token).put(`${baseUrl}`, data, config);
                console.debug(`DataService: ${baseUrl} - update(...) -> `, result.data)
                return result
            }
            return returnPromise()
        }

        function patch(data, config, token) {
            console.debug(`DataService: ${baseUrl} - patch(...)`, data, config)
            async function returnPromise() {
                const result = await context.apiClientBuilder(token).patch(`${baseUrl}`, data, config);
                console.debug(`DataService: ${baseUrl} - patch(...) -> `, result.data)
                return result
            }
            return returnPromise()
        }

        function remove(id, config, token) {
            console.debug(`DataService: ${baseUrl} - remove(...)`, id, config)
            async function returnPromise() {
                const result = await context.apiClientBuilder(token).delete(`${baseUrl}/${id}`, config);
                console.debug(`DataService: ${baseUrl} - remove(...) -> `, result.data)
                return result
            }
            return returnPromise()
        }

        function removeAll(config, token) {
            console.debug(`DataService: ${baseUrl} - removeAll(...)`, config)
            async function returnPromise() {
                const result = await context.apiClientBuilder(token).delete(`${baseUrl}`, config);
                console.debug(`DataService: ${baseUrl} - removeAll(...) -> `, result.data)
                return result
            }
            return returnPromise()
        }

        function findByName(name, config, token) {
            console.debug(`DataService: ${baseUrl} - findByName(...)`, config)
            async function returnPromise() {
                const result = await context.apiClientBuilder(token).get(`${baseUrl}?name=${name}`, config);
                console.debug(`DataService: ${baseUrl} - findByName(...) -> `, result.data)
                return result
            }
            return returnPromise()
        }

        return {
            getAll,
            get,
            create,
            update,
            patch,
            remove,
            removeAll,
            findByName
        }

    }

    return {
        refresh: context.refresh,
        refreshCounter: context.refreshCounter,
        endpoints : buildEnpoints(baseUrl),
        adhocEndpoints : buildEnpoints
    }
}

export default useDataService;