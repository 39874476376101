import React from "react";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const isEnvValid = domain && clientId && audience;

if (!isEnvValid) {
  throw new Error("Missing environment variables.");
}

const dotenv = {
  domain,
  clientId,
  audience,
};

export const EnvContext = React.createContext({
  ...dotenv,
  getApiServerUrl: () => Promise.resolve(),
  getRedirectUri: () => Promise.resolve(),
  getTenant: () => Promise.resolve(),
});

export const useEnv = () => {
  const context = React.useContext(EnvContext);
  if (!context) {
    throw new Error(`useEnv must be used within a EnvProvider`);
  }
  return context;
};

function getTenant() {
  const params = new URLSearchParams(window.location.search);
  const tenantParam = params.get('tenant')
  const {host} = window.location;
  const hostParts = host.split(".");
  const fallbackTenent = 'mehrer'

  if (tenantParam) {
    console.warn(`Parameter-Tenent: ${tenantParam}`)
    return tenantParam
  }

  if (hostParts.length > 2) {
    const subdomain = hostParts[0]
    console.debug(`Subdomain-Tenant: ${subdomain}`)
    return subdomain
  }

  if (host && host.startsWith('localhost')) {
    console.warn(`Development-Tenant: ${fallbackTenent}`)
    return fallbackTenent
  }

  throw new Error("Tenant is not identifiable")
}

function getApiServerUrl(){
  const {host} = window.location;
  if (host.startsWith('localhost')){
    return 'http://localhost:8080';
  }
  const strings = host.split(".");
  const subdomain = strings[0];
  const regEx = new RegExp(subdomain, "g");
  return host.replace(regEx , 'https://api');
}
function getRedirectUri(){
  const {host, protocol} = window.location;
  return `${protocol}//${host}/callback`;
}

export const EnvProvider = (props) => <EnvContext.Provider
    value={{
      ...dotenv,
      getApiServerUrl,
      getRedirectUri,
      getTenant
    }} {...props} />;
