// @mui
import { LoadingButton } from '@mui/lab';
// hooks
import PropTypes from "prop-types";
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------
LoginForm.propTypes = {
  returnTo: PropTypes.string,
};

export default function LoginForm({ returnTo }) {
  const { login } = useAuth();

  const onSubmit = async () => {
    try {
      await login(returnTo);
      // eslint-disable-next-line no-empty
    } catch (ignored) {}
  };

  return (
    <LoadingButton sx={{backgroundColor: '#f50101', borderRadius: 0.5, fontWeight: 'normal', width: 320, height: 52, boxShadow: 0}} size="large" type="submit" variant="contained" onClick={() => onSubmit()}>
        Zum Login
    </LoadingButton>
  );
}
