import PropTypes from 'prop-types';
import {createContext, useState} from 'react';
import axios from "axios";
import {useEnv} from "./EnvContext";

const DataServiceContext = createContext();

DataServiceProvider.propTypes = {
    children: PropTypes.node,
};

function DataServiceProvider({ children }) {
    const [refreshCounter, setRefreshCounter] = useState(0);
    const { getApiServerUrl, getTenant } = useEnv()

    function refresh() {
        setRefreshCounter(refreshCounter + 1)
    }

    function apiClientBuilder(token) {
        return axios.create({
            baseURL: `${getApiServerUrl()}/api`,
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
                "X-Tenant": getTenant()
            }
        })
    }

    return (
        <DataServiceContext.Provider value={{refreshCounter, refresh, apiClientBuilder}}>
            {children}
        </DataServiceContext.Provider>
    )
}

export {DataServiceContext, DataServiceProvider};

